import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constants } from '../constant/constants';
import { User } from '../model/user';
import { SharedService } from './shared.service';
import { signOut, fetchAuthSession, fetchUserAttributes, signInWithRedirect, SignInWithRedirectInput } from 'aws-amplify/auth';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(private router: Router,
        private sharedService: SharedService) {
    }

    /**
     * Create the User Object from CognitoUserAttributes
     *
     * @returns {User}
     * @param connectionId
     * @param attributes
     */
    private static createUser(attributes): User {
        const user = new User();
        // Populate the attributes from Idp.
        user.preferredName = attributes['preferred_username'];
        user.userName = attributes['name'];
        user.firstName = attributes['given_name'];
        user.middleName = attributes['middle_name'];
        user.lastName = attributes['family_name'];
        user.guid = attributes['custom:etsguid'];
        user.emailAddress = attributes['email'];
        return user;
    }

    /**
     * Initialize Amplify
     *
     * @param isRedirectFromIdp
     */
    init(isRedirectFromIdp: boolean): void {
        this.postConfigProcess(isRedirectFromIdp);
    }

    /**
     * Initialize Amplify on page reload
     *
     * @param location current window location
     */
    initOnReload(location: Location): void {
        this.postReload(location);
    }

    /**
     * Signout the user or invalidate the sesion
     *
     * @param logoutReasonTypecode
     *
     */
    invalidateSession(logoutReasonTypecode: string): void {
        sessionStorage.setItem(Constants.LOG_OUT_MSG, JSON.stringify(logoutReasonTypecode));
        signOut();
    }

    /**
     * Signout the user globally.
     *
     */
    sessionLogout(): void {
        // Update Login History and Logout User
        signOut({ global: true });
    }

    /**
     * Check if Apmplify authenticated the User
     *
     * @return {Observable<any>}
     */
    isAuthenticated(): Observable<any> {
        return new Observable(subscriber => {
            fetchAuthSession().then(data => {
                subscriber.next(true);
                subscriber.complete();
            }).catch(() => {
                subscriber.next(false);
                subscriber.complete();
            });
        });
    }

    /**
     * Create User and Save Login Session
     *
     * @returns {Observable<any>}
     * @param connectionId
     */
    setUpIdentity(): Observable<any> {
        return new Observable(subscriber => {
            // inside an async function
            // Run this after the sign-in
            fetchUserAttributes().then(data => {
                console.log('currentAuthenticatedUserData', data)
                const currentUser: User = AuthenticationService.createUser(data);
                // Save User Login Session and Retrieve Resource Permission
                sessionStorage.setItem(Constants.AUTH_TOKEN, btoa(JSON.stringify(currentUser)));
                subscriber.next(currentUser);
                subscriber.complete();
            }).catch((err) => {
                console.log(`CurrentSession Error: ${err}`);
                subscriber.error(err);
            });
        });
    }

    /**
     * redirect user to login with AD.
     */
    private login(): void {
        const customIdentityProvider =  (window?.location?.hostname?.indexOf('ext') > -1) ? environment.CUSTOM_IDENTITY_PROVIDER_EXT : environment.CUSTOM_IDENTITY_PROVIDER;
        const options: SignInWithRedirectInput = { provider: { custom : customIdentityProvider }};
        signInWithRedirect(options);
    }

    /**
     * Check if OAuth Flow has been completed else login User
     *
     * @returns {Observable<any>}
     * @param isRedirectFromIdp
     */
    private postConfigProcess(isRedirectFromIdp: boolean): void {
        if (!isRedirectFromIdp) {
            this.isAuthenticated().subscribe(
                data => {
                    if (data) {
                        if (sessionStorage.getItem('currentUser') != null) {
                            this.router.navigate(['/epms-admin-home']);
                        } else {
                            const currentUser = sessionStorage.getItem(Constants.AUTH_TOKEN);
                            localStorage.clear();
                            sessionStorage.clear();
                            if (currentUser) {
                                sessionStorage.removeItem(Constants.AUTH_TOKEN);
                                sessionStorage.removeItem(Constants.SESSION_CONTEXT);
                            } 
                            this.login();
                            
                        }
                    } else {
                        // User is not logged in begin Authentication Process
                        if (!isRedirectFromIdp) {
                            this.login();
                        }
                    }
                },
                () => {
                    console.log('Error checking if the user is authenticated", error');
                }
            );
        }
    }

    private postReload(location: Location): void {
        this.isAuthenticated().subscribe(
            data => {
                if (data) {
                    const currentUser: User = JSON.parse(atob(
                        sessionStorage.getItem(Constants.AUTH_TOKEN)));

                    if (currentUser && currentUser.guid && location) {
                        this.sharedService.onLoggedInUser(currentUser);
                        this.router.navigateByUrl(`${location.pathname}${location.search}`);
                    } else {
                        sessionStorage.removeItem(Constants.AUTH_TOKEN);
                        sessionStorage.removeItem(Constants.SESSION_CONTEXT);
                        this.router.navigate(['unauthorizedUser']);
                        this.invalidateSession(Constants.LOGOUT_REASON_LOGOUT);
                    }
                } else {
                    this.login();
                }
            },
            () => {
                console.log('Error checking if the user is authenticated", error');
            }
        );
    }
}
