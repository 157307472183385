import {ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {faChevronLeft, faDownload} from '@fortawesome/free-solid-svg-icons';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderService} from '../../service/order.service';
import {RefundService} from 'src/app/service/refund.service';
import {Profile} from 'src/app/model/profile-permissions';
import {SharedService} from 'src/app/service/shared.service';
import {OrderSearchDetailsPdfService} from '../../service/order-search-details-pdf.service';
import {MatLineItemSummaryDetailsComponent} from '../../mat-line-item-summary/mat-line-item-summary.component';
import {OrderSearchService} from '../../service/order-search.service';
import { ScrollToService } from 'src/app/service/scroll-to-service';

@Component({
  selector: 'app-order-search-details',
  templateUrl: './order-search-details.component.html',
  styleUrls: ['./order-search-details.component.scss'],
})
export class OrderSearchDetailsComponent implements OnInit, OnDestroy {
  faChevronLeft = faChevronLeft;
  modalRef: BsModalRef;
  txData: any;
  loadTxDataEvent: any;
  txDataList: any;
  relatedOrdersData: any;
  orderState: string;
  profile: Profile;
  settlementDetails: string;
  faDownload = faDownload;

  @ViewChild(MatLineItemSummaryDetailsComponent)
  matLineItemSummaryDetails: MatLineItemSummaryDetailsComponent;
  audit: any;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private refundService: RefundService,
    private sharedService: SharedService,
    private orderSearchDetailsPdfService: OrderSearchDetailsPdfService,
    private orderSearchService: OrderSearchService,
    public scrollToService: ScrollToService,
    public cdRef: ChangeDetectorRef
  ) {
    this.loadTxDataEvent = this.refundService.resetRefundForm.subscribe(
      (res) => {
        if (res && res == 'GET_TXDATA') {
          this.route.params.subscribe((data) => {
            const transactionId = data['transactionId'];

            this.loadTransactionData(transactionId);
          });
        }
      }
    );
  }

  ngOnInit(): void {
    this.route.params.subscribe((data) => {
      const transactionId = data.transactionId;

      this.loadTransactionData(transactionId);
    });

    this.route.queryParams?.subscribe((data) => {
      this.orderState = data['orderState'];
    });
    this.route.queryParams.subscribe((data) => {
      this.audit = data['audit'];
    });
    this.sharedService.profileInfo$.subscribe((profileInfo) => {
      this.profile = profileInfo;
    });
  }

  loadTransactionData(txId) {
    const obs = this.orderService.getTxData(txId);
    obs.subscribe((data) => {
      this.txData = data;
      const orderId = data['order'].epmsOrderId;
      this.loadTransactions(orderId,txId);
      this.loadRelatedOrdersData(orderId);
      this.loadOrderState(orderId, txId);
      this.cdRef.detectChanges();
    });
  }

  isChargeBack() {
    return (
      this.txData?.transactionTypeCode == 'REVERSE_CHARGEBACK' ||
      this.txData?.transactionTypeCode == 'CHARGEBACK'
    );
  }

  loadOrderState(orderId, txId, relatedOrderState?) {
    const searchRequest = [{ field: 'orderId', value: orderId }];
    const obs = this.orderSearchService.orderSearchRecordDetail(searchRequest);
    obs.subscribe((data: any) => {
      if (data.content) {
        const filtered = data.content.filter((d) => d.transactionId == txId)[0];
        this.orderState = filtered ? filtered.orderState : '';
        if (relatedOrderState) {
          this.router.navigate(['/order-search-details', txId], {
            queryParams: { orderState: this.orderState },
          });
        }
      }
    });
  }

  loadTransactions(orderId,txnId) {
    const obs = this.orderService.getTranscationsData(orderId, txnId);
    obs.subscribe((data) => {
      this.txDataList = data;
    });
  }

  loadRelatedOrdersData(orderId) {
    const obs = this.orderService.getRelatedOrdersData(orderId);
    obs.subscribe((data) => {
      this.relatedOrdersData = data;
    });
  }

  openModal(linkedoverriderefund: TemplateRef<any>) {
    this.modalRef = this.modalService.show(linkedoverriderefund);
  }

  reviseSearch() {
    this.router.navigate(['/order-search-result']);
  }

  reviseAuditLog() {
    this.router.navigate(['/virtual-terminal-audit-log'], {
      queryParams: { backAudit: true },
    });
  }

  ngOnDestroy() {
    this.loadTxDataEvent.unsubscribe();
  }
  
  exportPdf() {
    const lineItemsData = this.matLineItemSummaryDetails.txData
      ? this.matLineItemSummaryDetails.txData['lineItems']
      : [];
    this.orderSearchDetailsPdfService.createPdf(
      this.txData,
      this.orderState,
      lineItemsData,
      this.txDataList,
      this.relatedOrdersData
    );
  }
  showSettlementDetails(
    transactionData: any,
    detailTemplate: TemplateRef<any>
  ) {
    switch (transactionData.service) {
      case 'AUTHORIZATION':
        this.getProcessorDetails(transactionData, 'authorization');
        break;
      case 'REFUND':
        this.getProcessorDetails(transactionData, 'refund');
        break;
      case '3DS PAYER AUTHENTICATION':
        this.getProcessorDetails(transactionData, '3ds');
        break;
      case 'SALE PGM RESPONSE':
        this.getProcessorDetails(transactionData, 'salepgm', true);
        break;
      case 'REFUND PGM RESPONSE':
        this.getProcessorDetails(transactionData, 'refundpgm', true);
        break;
      default:
        this.settlementDetails = transactionData.reconciliationDetailLog
          ? JSON.parse(transactionData.reconciliationDetailLog)
          : 'No Data To Display.';
        break;
    }

    this.modalRef = this.modalService.show(detailTemplate, {
      class: 'gray modal-lg',
      ignoreBackdropClick: true,
    });
  }

  /***
   * get Processor Details From S3
   * @transactionData trasaction data
   * @eventType event type
   * @displayResponse to display response
   *  */
  getProcessorDetails(
    transactionData: any,
    eventType: string,
    displayResponse?: boolean
  ) {
    this.orderService
      .getProcessorDetailsFromS3(
        transactionData.orderTransactionId,
        eventType,
        this.txData?.paymentTypeCode
      )
      .then((res) => {
        if (!displayResponse) {
          this.settlementDetails =
            res instanceof Array
              ? this.prepareProcessorResponses(res)
              : decodeURI(res.Body);
        } else if (displayResponse && res) {
          this.settlementDetails = res;
        }
      })
      .catch(() => {
        this.settlementDetails = 'No Payment logs found ';
      });
  }

  prepareProcessorResponses(processorResponses: any): string {
    let processorResponseDetails = '';
    processorResponses.forEach((processorResponse) => {
      processorResponseDetails +=
        decodeURI(JSON.parse(processorResponse).Body) + '\n\n\n';
    });
    return processorResponseDetails;
  }
}
