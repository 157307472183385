import { Injectable } from "@angular/core";
import FileSaver from "file-saver";
import { CdnLoadService } from "./cdn-load.service";

declare let XLSX:any;

@Injectable({
    providedIn: 'root',
})
export class ExportService{

  readonly DATE_TIME_FORMAT:string='yyyy-MM-dd HH:mm:ss';
  readonly DATE_FORMAT:string='MMMM d, yyyy';
  readonly TIMEZONE:string='en-US';

  constructor(private loadScript: CdnLoadService) {
    this.loadScript.load("sheetJs");
  }

  exportFile(fileType: string, filename: string, sheetname: string, data: any[]) {
    if (data.length === 0) {
      return;
    }
    const ws = XLSX.utils.json_to_sheet(data);
    if (fileType === 'csv') {
      this.preventFormulaInjection(ws);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetname);
      const csvData = XLSX.utils.sheet_to_csv(wb.Sheets[sheetname]);
      if (csvData) {
        FileSaver.saveAs(new Blob([csvData]), filename + '.' + fileType);
      } else {
        alert("No data to export.");
      }

    } else {
       // Step 1: Get the headers (keys of the first object)
        const headers = Object.keys(data[0]);

        // Step 2: Convert the array of objects into an array of arrays
        const formattedData = data.map(row => 
          headers.map(fieldName => row[fieldName])
        );

        // Add the headers as the first row
        formattedData.unshift(headers);

        // Step 3: Create a worksheet from the array of arrays
        const ws2 = XLSX.utils.aoa_to_sheet(formattedData);

        // 4. Apply a global text format to all cells in the worksheet
        const range = XLSX.utils.decode_range(ws2['!ref']);  // Get the range of the worksheet

        // Apply text format to all cells in the sheet by updating the style globally
        for (let row = range.s.r; row <= range.e.r; row++) {
          for (let col = range.s.c; col <= range.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            const cell = ws2[cellAddress];

            // If the cell exists, set its format to text (numFmt: '@')
            if (cell) {
              // Apply the text format to each cell
              cell.s = { numFmt: '@' };
            }
          }
        }

        // Create a new workbook and append the worksheet
        const wb2 = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb2, ws2, sheetname);

        // Export to Excel file
        XLSX.writeFile(wb2, filename + '.' + fileType);
    }
  }

  // Function to prevent formula injection by adding a space
  preventFormulaInjection(ws) {
    for (const cell in ws) {
      if (ws.hasOwnProperty(cell) && cell[0] !== '!') {
        ws[cell].v = ' ' + ws[cell].v;
      }
    }
  }
}
