<app-loader [enableLoader]="enableLoader"></app-loader>
<div class="mt-4 container-xl" *ngIf="loggedInUser?.oscDetails?.length > 0">
    <div class="row">
        <div class="col-md-12">
            <h1 class="padding">Order Form</h1>
            <p></p>
            <div class="card">
                <div class="card-body" id="dropdown-arrow-mat">
                    <form name="orderForm" [formGroup]="orderFormGroup" class="row d-flex align-items-center">
                        <div class="col-md-4 mb-3">
                            <label for="originatingSystemCode"><span class="required-asterisk">*</span> Originating System Code</label>
                            <mat-select placeholder="Select..." id="originatingSystemCode" class="form-select form-select-md" formControlName="originatingSystemCode" >
                                <mat-option value="null" disabled>Select...</mat-option>
                                <ng-container *ngFor="let oscCode of oscConfigCodes">
                                    <mat-option [id]="oscCode" [value]="oscCode">{{oscCode}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="oscSessionId"><span class="required-asterisk">*</span> OSC Session Id</label>
                            <input class="form-control form-control-md" formControlName="oscSessionId" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="epmsOrderId"> EPMS Order Id</label>
                            <input class="form-control form-control-md" formControlName="epmsOrderId"  />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="orderAmount"> Amount</label>
                            <input class="form-control form-control-md" formControlName="orderAmount" type="number"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="baseTaxAmount"> Tax Amount</label>
                            <input class="form-control form-control-md" formControlName="baseTaxAmount" type="number"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="baseCurrency"><span class="required-asterisk">*</span> Currency</label>
                            <input class="form-control form-control-md" formControlName="baseCurrency" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="displayLanguage"> Display Language</label>
                            <mat-select placeholder="Select..." id="displayLanguage" formControlName="displayLanguage" class="form-select form-select-md">
                                <mat-option id="en" value="en">English</mat-option>
                                <mat-option id="zh-cn" value="zh-cn">Mandarin Chinese</mat-option>
                                <!-- <mat-option id="ja" value="ja">Japanese</mat-option>
                                <mat-option id="de" value="de">German</mat-option>
                                <mat-option id="pt" value="pt">Portuguese</mat-option>
                                <mat-option id="ko" value="ko">Korean</mat-option>
                                <mat-option id="fr" value="fr">French</mat-option>
                                <mat-option id="es" value="es">Spanish</mat-option>
                                <mat-option id="tr" value="tr">Turkish</mat-option> -->
                            </mat-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="sourceType"><span class="required-asterisk">*</span> Source Type</label>
                            <mat-select placeholder="Select..." id="sourceType" formControlName="sourceType" class="form-select form-select-md" >
                                <mat-option id="sale" value="sale">Sale</mat-option>
                                <mat-option id="subscription" value="subscription">Subscription</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="testCenterCountry"> TestCenter Country</label>
                            <mat-select placeholder="Select..." id="testCenterCountry" class="form-select form-select-md"
                                    formControlName="testCenterCountry">
                                <mat-option disabled>- Select -</mat-option>
                                <mat-option [id]="country.id" *ngFor="let country of countryList" [value]="country.id">{{country.name}}</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-md-12">
                            <h4>Line Item Details:</h4>
                        </div>
                        <div class="col-md-12">
                            <mat-card class="mat-elevation-z8">
                                <mat-card-actions>
                                    <button (click)="addLineItem()">
                                        <mat-icon aria-hidden="true" aria-label="Add Lineitem icon">add</mat-icon>
                                    </button>
                                </mat-card-actions>
                                <mat-table #table class="mat-elevation-z0" [dataSource]="lineItemDataSource"
                                    [trackBy]="trackRows">
                                    <!-- Action Column -->
                                    <ng-container matColumnDef="action">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <button (click)="deleteLineItem(i)">
                                                    <mat-icon aria-hidden="true" aria-label="Delete Lineitem icon">delete</mat-icon>
                                                </button>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Product Column -->
                                    <ng-container matColumnDef="productName">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label>Product Name</mat-label>
                                                    <input matInput placeholder="Product Name" id="productName{{i}}"
                                                        name="productName{{i}}" formControlName="productName" />
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="productDescription">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label> Product Description</mat-label>
                                                    <input matInput placeholder="Product Description" id="productDescription{{i}}"
                                                        name="productDescription{{i}}" formControlName="productDescription" />
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="productSKU">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label>Product SKU</mat-label>
                                                    <input matInput placeholder="Product SKU" id="productSku_{{i}}"
                                                        name="productSku_{{i}}" formControlName="productSKU" />
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="productCode">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label> Product Code</mat-label>
                                                    <input matInput placeholder="Product Code" id="productCode{{i}}"
                                                        name="productCode{{i}}" formControlName="productCode" />
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="quantity">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label> Quantity</mat-label>
                                                    <input matInput placeholder="Quantity" id="quantity{{i}}"
                                                        name="quantity{{i}}" formControlName="quantity" />
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="unitPrice">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label><span class="required-asterisk">*</span> Unit Price</mat-label>
                                                    <input matInput placeholder="Unit Price" id="unitPrice{{i}}"
                                                        name="unitPrice{{i}}" formControlName="unitPrice" />
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="taxAmount">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label> Tax Amount</mat-label>
                                                    <input matInput placeholder="Tax Amount" id="taxAmount{{i}}"
                                                        name="taxAmount{{i}}" formControlName="taxAmount" />
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-row *matRowDef="let row; columns: lineItemDetailColumns"></mat-row>
                                </mat-table>
                            </mat-card>
                        </div>
                        <div class="col-md-12">
                            <h4>Shipping Information: </h4>
                            <app-contact-form formControlName="shippingInfo" [isDropDown]=true></app-contact-form>
                        </div>
                        <div class="col-md-12">
                            <h4>Program Application Details: </h4>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="glProjectText"><span class="required-asterisk">*</span> Project</label>
                            <input class="form-control form-control-md" formControlName="glProjectText" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="resourceText"> Resource Text</label>
                            <input class="form-control form-control-md" formControlName="resourceText" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="serviceCode"> Service Code</label>
                            <input class="form-control form-control-md" formControlName="serviceCode" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="glProductText"> GL Product</label>
                            <input class="form-control form-control-md" formControlName="glProductText" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="glActivityText"> GL Activity</label>
                            <input class="form-control form-control-md" formControlName="glActivityText" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="programSpecificKey"> Program Specific Key</label>
                            <input class="form-control form-control-md" formControlName="programSpecificKey" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="modeOfReceipt"> Mode Of Receipt</label>
                            <mat-select placeholder="Select..." id="modeOfReceipt" formControlName="modeOfReceipt" class="form-select form-select-md">
                                <mat-option id="Internet" value="N">Internet</mat-option>
                                <mat-option id="InPerson" value="I">In Person</mat-option>
                                <mat-option id="Phone" value="T">Phone</mat-option>
                                <mat-option id="VRS" value="V">VRS</mat-option>
                                <mat-option id="Mail" value="M">Mail</mat-option>
                                <mat-option id="FAX" value="F">FAX</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="jeAccountNumber"> JE Account Number</label>
                            <input class="form-control form-control-md" formControlName="jeAccountNumber" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="customerID"><span class="required-asterisk">*</span> Customer ID</label>
                            <input class="form-control form-control-md" formControlName="customerID" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="programDefinedDate"> Program Defined Date</label>
                            <input class="form-control form-control-md" formControlName="programDefinedDate" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantDescriptor"><span class="required-asterisk">*</span> Merchant Descriptor</label>
                            <input class="form-control form-control-md" formControlName="merchantDescriptor" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantDescriptorContact"> Merchant Contact</label>
                            <input class="form-control form-control-md" formControlName="merchantDescriptorContact" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="fdCustomerType">FD Customer Type</label>
                            <input class="form-control form-control-md" formControlName="fdCustomerType" />
                        </div>
                        <div class="col-md-12">
                            <h4>Subscription Fields: </h4>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="customerType"> Customer Type</label>
                            <mat-select placeholder="Select..." id="customerType" formControlName="customerType" class="form-select form-select-md">
                                <mat-option value="" disabled>Select...</mat-option>
                                <mat-option id="NEW" value="NEW">NEW</mat-option>
                                <mat-option id="EXISTING" value="EXISTING">EXISTING</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="registrationDate"> Registration Date</label>
                            <input class="form-control form-control-md" formControlName="registrationDate" type="date" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="createSubscriptionID"> Create Subscription ID</label>
                            <mat-select placeholder="Select..." id="createSubscriptionID" formControlName="createSubscriptionID" class="form-select form-select-md">
                                <mat-option value="" disabled>Select...</mat-option>
                                <mat-option id="true" value="true">True</mat-option>
                                <mat-option id="false" value="false">False</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="frequency"> Frequency</label>
                            <mat-select placeholder="Select..." id="frequency" formControlName="frequency" class="form-select form-select-md" >
                                <mat-option value="" disabled>Select...</mat-option>
                                <mat-option id="ON_DEMAND" value="ON_DEMAND">ON_DEMAND</mat-option>
                                <mat-option id="DAILY" value="DAILY">DAILY</mat-option>
                                <mat-option id="WEEKLY" value="WEEKLY">WEEKLY</mat-option>
                                <mat-option id="MONTHLY" value="MONTHLY">MONTHLY</mat-option>
                                <mat-option id="YEARLY" value="YEARLY">YEARLY</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="numberOfPayments"> Number of Payments</label>
                            <input class="form-control form-control-md" formControlName="numberOfPayments" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="subscriptionRenew"> Subscription Renew</label>
                            <mat-select placeholder="Select..." id="subscriptionRenew" class="form-select form-select-md">
                                <mat-option value="" disabled>Select...</mat-option>
                                <mat-option id="true" value="true">True</mat-option>
                                <mat-option id="false" value="false">False</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="startDate"> Start Date</label>
                            <input class="form-control form-control-md" formControlName="startDate" type="date" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="subscriptionAmount"> Subscription Amount</label>
                            <input class="form-control form-control-md" formControlName="subscriptionAmount" />
                        </div>
                        <div class="col-md-12">
                            <h4>Merchant Fields: </h4>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantDefinedField1"> Merchant Defined field1</label>
                            <input class="form-control form-control-md" formControlName="merchantDefinedField1"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantDefinedField2"> Merchant Defined field2</label>
                            <input class="form-control form-control-md" formControlName="merchantDefinedField2"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantDefinedField3"> Merchant Defined field3</label>
                            <input class="form-control form-control-md" formControlName="merchantDefinedField3"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantDefinedField4"> Merchant Defined field4</label>
                            <input class="form-control form-control-md" formControlName="merchantDefinedField4"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantDefinedField5"> Merchant Defined field5</label>
                            <input class="form-control form-control-md" formControlName="merchantDefinedField5"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantDefinedField6"> Merchant Defined field6</label>
                            <input class="form-control form-control-md" formControlName="merchantDefinedField6"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantDefinedField7"> Merchant Defined field7</label>
                            <input class="form-control form-control-md" formControlName="merchantDefinedField7"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantDefinedField8"> Merchant Defined field8</label>
                            <input class="form-control form-control-md" formControlName="merchantDefinedField8"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantDefinedField9"> Merchant Defined field9</label>
                            <input class="form-control form-control-md" formControlName="merchantDefinedField9"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantDefinedField10"> Merchant Defined field10</label>
                            <input class="form-control form-control-md" formControlName="merchantDefinedField10"/>
                        </div>
                        <div class="col-md-12">
                            <h4>Response Fields: </h4>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantSuccessUrl"> Merchant Success URL</label>
                            <input class="form-control form-control-md" formControlName="merchantSuccessUrl"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantRejectUrl"> Merchant Reject URL</label>
                            <input class="form-control form-control-md" formControlName="merchantRejectUrl"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantErrorUrl"> Merchant Error URL</label>
                            <input class="form-control form-control-md" formControlName="merchantErrorUrl"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantTimeoutUrl"> Merchant Timeout URL</label>
                            <input class="form-control form-control-md" formControlName="merchantTimeoutUrl"/>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="merchantPostUrl">Merchant Post URL</label>
                            <input class="form-control form-control-md" formControlName="merchantPostUrl"/>
                        </div>
                        <div class="col-md-12">
                            <button class="btn btn-primary me-3" type="submit"
                                (click)="onSubmit()">Submit</button>
                            <button class="btn btn-primary btn-function" (click)="resetCvaOrderForm()">Clear</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
    <div class="col-2"></div>
    <div class="col-8  alert alert-danger" role="alert">
        Originating System Code or Program Group need to be associated in order to access the page.
    </div>
    <div class="col-2"></div>
</div>

<!-- raw detail modal -->
<ng-template #orderResponseTemplate>
    <div class="modal-header d-inline" align="end">
        <button mat-icon-button class="close-button" (click)="closeResponseModal()">
            <mat-icon class="close-icon">close</mat-icon>
          </button>
    </div>
    <div class="modal-body" style="white-space: break-spaces;overflow-wrap: break-word;">
        {{orderResponseDetails|json}}
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary btn-function" [cdkCopyToClipboard]="orderResponseDetails|json">Copy to
            clipboard</button>
        <button class="btn btn-primary btn-function" (click)="closeResponseModal()">Exit</button>
    </div>
</ng-template>