<div class="mat-elevation-z8">

  <div class="col-lg-3 col-md-3 col-12">
    <mat-form-field >
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>

  </div>
  <div>
    <mat-table [dataSource]="dataSource" matSort class="table table-responsive table-bordered" matSortDisableClear (matSortChange)="addCustomSort($event)">
      <ng-container matColumnDef="checked">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> Select<br> User</th>
        <td mat-cell *matCellDef="let element"> <span class="cdk-visually-hidden">{{element?.checked?.toString()?.toUpperCase()}}</span>
            <mat-checkbox [(ngModel)]="element.checked" [disabled]="element.isDisabled" (change)="filterTableList($event, element)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="userLoginID">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>User Login ID</th>
        <td mat-cell *matCellDef="let element"><a class="link" [routerLink]="['/user-management/user-details']"
                                                  [queryParams]="{userLoginID:element.userLoginID}"> {{element.userLoginID}}</a>
        </td>
      </ng-container>


      <ng-container matColumnDef="firstName">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>First Name</th>
        <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> Last Name</th>
        <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
      </ng-container>

      <ng-container matColumnDef="programGroup">
        <th class="header osc-code-td-header" mat-header-cell mat-sort-header *matHeaderCellDef> Program Group</th>
        <td class="osc-code-td-header osc-code-td" mat-cell *matCellDef="let element"> {{element.programGroup}} </td>
      </ng-container>

      <ng-container matColumnDef="osc">
        <th class="header osc-code-td-header" mat-header-cell mat-sort-header *matHeaderCellDef> OSC</th>
        <td class="osc-code-td-header osc-code-td" mat-cell *matCellDef="let element"> {{element.osc}} </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th class="header osc-code-td-header" mat-header-cell mat-sort-header *matHeaderCellDef> Role Name</th>
        <td class="osc-code-td-header osc-code-td" mat-cell *matCellDef="let element">{{element.role}}</td>
      </ng-container>

      <ng-container matColumnDef="roleDesc">
        <th class="header osc-code-td-header" mat-header-cell mat-sort-header *matHeaderCellDef> Role <br> Desc</th>
        <td class="osc-code-td-header osc-code-td"mat-cell *matCellDef="let element">{{element.roleDesc}}</td>
      </ng-container>

      <ng-container matColumnDef="userAccntType">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> User<br> Account Type</th>
        <td mat-cell *matCellDef="let element">{{element.userAccntType}}</td>
      </ng-container>
      <ng-container matColumnDef="userEmail">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> User<br> E-mail</th>
        <td mat-cell *matCellDef="let element">{{element.userEmail}}</td>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> Created Date</th>
        <td mat-cell *matCellDef="let element"> {{element?.createdDate|date}}</td>
      </ng-container>

      <ng-container matColumnDef="userLoginTimestamp">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> Last Login </th>
        <td mat-cell *matCellDef="let element"> {{element?.userLoginTimestamp|date}}</td>
      </ng-container>

      <ng-container matColumnDef="userId">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> User ID</th>
        <td mat-cell *matCellDef="let element"> {{element?.userId}}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> Status</th>
        <td mat-cell *matCellDef="let element"> {{element.state}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </mat-table>
  </div>
  <mat-paginator [length]="100" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]">
  </mat-paginator>
  <!-- <div class="bottom-tip">
    test
  </div> -->
</div>


