import { Injectable } from "@angular/core";
import FileSaver from "file-saver";
import { CdnLoadService } from "./cdn-load.servivce";

declare let XLSX:any;

@Injectable({
    providedIn: 'root',
})
export class ExportService{

  readonly DATE_TIME_FORMAT:string='yyyy-MM-dd HH:mm:ss';
  readonly DATE_FORMAT:string='MMMM d, yyyy';
  readonly TIMEZONE:string='en-US';


    constructor(private loadScript:CdnLoadService){   
        this.loadScript.load("sheetJs");
    }

    exportFile(fileType:string,filename:string,sheetname:string,data:any[]){
        if(data.length>0){
          const ws = XLSX.utils.json_to_sheet(data); 
          const wb = XLSX.utils.book_new();  
          XLSX.utils.book_append_sheet(wb, ws, sheetname);
          if(fileType==='csv'){
            let csvData="";
            wb.SheetNames.forEach((sheetName)=>{
              csvData+=XLSX.utils.sheet_to_csv(wb.Sheets[sheetName])
            });
            if(csvData!==""){
              FileSaver.saveAs(new Blob([csvData]), filename+'.'+fileType);
            }else{
              alert("No data to export.");
            } 
          }else{
            XLSX.writeFile(wb, filename+'.'+fileType);
          }
        }else{
          alert("No data to export.");
        }
      }
}