import {Component, Input, TemplateRef, ViewChild,} from '@angular/core';
import {MatPaginator, MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import {CustomPaginator} from './CustomPaginator';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {OrderSearchResponse} from '../model/order-search-response';
import {TableUtil} from "../util/table.util";
import {DatePipe, formatDate} from '@angular/common';
import { ExportService } from '../service/export-service';

@Component({
  selector: 'app-mat-fiter-table',
  templateUrl: './mat-fiter-table.component.html',
  styleUrls: ['./mat-fiter-table.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginator() }, DatePipe],
})
export class MatFiterTableComponent {

  displayedColumns: string[] = [
    'epmsOrderId',
    'transactionAttemptCount',
    'programSpecificKey',
    'createdDate',
    'settlementDate',
    'reconciliationDate',
    'createdBy',
    'originatingSystemCode',
    'shipToFirstName',
    'shipToLastName',
    'shipToCity',
    'shipToCountry',
    'orderState',
    'paymentMethodCode',
    'cardType',
    'usdAmount',
    'localCurrencyamount',
    'currencyCode',
    //'localCurrencyCode',
    'billToFirstName',
    'billToLastName',
    'billToCity',
    'billToCountry',
    'fraudScore',
    'reasonCode',
    'customerID',
    'pgmResponseCode',
    'pgmResponseDescription',
    'project',
    'jeAccountNumber',
    'fraudIndicator',
    'noMtchVndrSuppliedId',
    'fraudCustomerType',
    'arnRrnNo'
  ];
  dataSource ;
  all: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  modalRef: BsModalRef;
  @Input() orderSearchResult;
  isDataAvailable: boolean = false; //hide grid
  faDownload = faDownload;
  hideLocalCurrencyCode = true;
  pgIndex: number = 0;
  @Input() sourceRoute;

  exportedSearchResultColumnsJson = [];

  exportOption = 'xls';

  constructor(
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private exportService:ExportService
  ) {
   this.dataSource = new MatTableDataSource<OrderSearchResponse>();
  }

  ngAfterViewInit() {
    setTimeout(() => {
    if (this.sourceRoute === 'order-search') {
      sessionStorage.getItem('pageSize') ? sessionStorage.removeItem('pageSize') : '';
      sessionStorage.getItem('pageIndex') ? sessionStorage.removeItem('pageIndex') : '';

      sessionStorage.getItem('sortActive') ? sessionStorage.removeItem('sortActive') : '';
      sessionStorage.getItem('sortDirection') ? sessionStorage.removeItem('sortDirection') : '';
    }

    this.paginator.showFirstLastButtons = true;
    this.paginator.pageSize = parseInt(sessionStorage.getItem('pageSize')) ;
    this.pgIndex = parseInt(sessionStorage.getItem('pageIndex'));
    this.dataSource.paginator = this.paginator;

    this.updateSort();
    //adding data in after view init
    //so that paginator loads beforehand to increase perfomance with large dataset
    if (this.orderSearchResult?.content?.length > 0) {
      this.dataSource.data = this.orderSearchResult.content;
      this.isDataAvailable = true; // show grid
    }
   }, 0);
  }

  updateSort(){
    //default sort desc on transaction date (createdDate)
    let sortDirection: any = sessionStorage.getItem('sortDirection');
    let sortActive = sessionStorage.getItem('sortActive');

    if(!sortActive){
      sortActive = 'createdDate';
    }
    if(!sortDirection){
      sortDirection = 'desc';
    }
    const sortState: Sort = {
      active:  sortActive,
      direction: sortDirection
    };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    // this.sort.sortChange.emit(sortState);
    this.dataSource.sort = this.sort;

    setTimeout(() => {
      this.sort.sortChange.emit(sortState);
    },500);
  }

  filterGrid(filterResult) {
    if (filterResult?.content?.length > 0) {
      this.dataSource.data = filterResult.content;
      this.isDataAvailable = true; // show grid
    }
  }

  onChangePage(pe: PageEvent) {
    sessionStorage.setItem('pageIndex', pe.pageIndex.toString());
    sessionStorage.setItem('pageSize', pe.pageSize.toString());


  }

  onSortChange(sortState:Sort){
    sessionStorage.setItem('sortDirection', sortState.direction);
    sessionStorage.setItem('sortActive', sortState.active);
    this.addCustomSort(sortState);
  }

  getTableData() {
    const clonedDataList = this.dataSource.data.map(row => {
      const cloneRow = {...row};
      if (cloneRow.usdAmount) {
        // @ts-ignore
        cloneRow.amount = cloneRow.usdAmount.toFixed(2);
      }
      delete cloneRow.usdAmount;
      const df='yyyy-MM-dd HH:mm:ss';
      if (cloneRow.createdDate) {
        // @ts-ignore
        cloneRow.createdDate = this.datePipe.transform(cloneRow.createdDate, df);
      }
      if (cloneRow.settlementDate) {
        // @ts-ignore
        cloneRow.settlementDate = this.datePipe.transform(cloneRow.settlementDate, df);
      }
      if (cloneRow.reconciliationDate) {
        // @ts-ignore
        cloneRow.reconciliationDate = this.datePipe.transform(cloneRow.reconciliationDate, df);
      }
      return cloneRow;
    });
    return clonedDataList;
  }

  formatSearchResults(data:any[]){
    let index=0;
    this.exportedSearchResultColumnsJson=[];
    data.forEach(json=>{
      let expJson={
          'Order Id':json['epmsOrderId'],
          'Transaction Attempt Count':json['transactionAttemptCount'],
          'Program Specific Key':json['programSpecificKey'],
          'Transaction Created Date':formatDate(json['createdDate'],this.exportService.DATE_TIME_FORMAT,this.exportService.TIMEZONE),
          'Settlement Date':json['settlementDate']!==null?formatDate(json['settlementDate'],this.exportService.DATE_TIME_FORMAT,this.exportService.TIMEZONE):null,
          'Reconciliation Date':json['reconciliationDate']!==null?formatDate(json['reconciliationDate'],this.exportService.DATE_TIME_FORMAT,this.exportService.TIMEZONE):null,
          'Created By':json['createdBy'],
          'OSC':json['originatingSystemCode'],
          'Ship to First Name':json['shipToFirstName'],
          'Ship to Last Name':json['shipToLastName'],
          'Ship to City':json['shipToCity'],
          'Ship to Country':json['shipToCountry'],
          'Order State':json['status'],
          'Payment Method':json['paymentMethodCode'],
          'Card Type':json['cardType'],
          'Amount':json['usdAmount'],
          'Local Currency Amount':json['localCurrencyamount'],
          'Currency Code':json['currencyCode'],
          'Bill To First Name':json['billToFirstName'],
          'Bill To Last Name':json['billToLastName'],
          'Bill To City':json['billToCity'],
          'Bill To Country':json['billToCountry'],
          'Fraud Score':json['fraudScore'],
          'Reason Code':json['reasonCode'],
          'Customer ID':json['customerID'],
          'Program Response Code':json['pgmResponseCode'],
          'Program Response Description':json['pgmResponseDescription'],
          'Project':json['project'],
          'JE Account Number':json['jeAccountNumber'],
          'TC-40 Flag':json['fraudIndicator'],
          'No Match Vendor Supplied Order ID':json['noMtchVndrSuppliedId'],
          'Fd Customer Type':json['fraudCustomerType'],
          'ARN/RRN':json['arnRrnNo'],
          'Browser IP':json['custIpAddr'],
          'Browser Type':json['browserTyp'],
          'Browser Language':json['browserLang'],
          'Browser Version':json['browserVersion'],
          'Device Type':json['deviceType'],
          'OS Type':json['opSysType']
        };
     
      this.exportedSearchResultColumnsJson.push(expJson);
      index++;
    });
  }

  exportData() {
    const timeSpan = new Date().toISOString();
    if(this.exportOption!=='xml'){
      this.formatSearchResults(this.dataSource.data);
    }
    switch (this.exportOption) {
      case 'xls':
        this.exportService.exportFile('xlsx', `searchResults-${timeSpan}`,'searchResults',this.exportedSearchResultColumnsJson);
        break;
      case 'csv':
        this.exportService.exportFile('csv',  `searchResults-${timeSpan}`, 'searchResults',this.exportedSearchResultColumnsJson);
        break;
      case 'xml':
        TableUtil.exportToXml(this.getTableData(), `searchResults-${timeSpan}`);
        break;
    }
  }

  openModal(exportSave: TemplateRef<any>) {
    this.modalRef = this.modalService.show(exportSave, {backdrop: 'static'});
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addCustomSort(sort: Sort) {
    let sortedData;
    const data = this.dataSource.data.slice();
    if (!sort.active ) {
      sortedData = data;
    } else {
      sortedData = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'epmsOrderId':
            return this.compare((a.epmsOrderId || '').toLowerCase(), (b.epmsOrderId || '').toLowerCase(), isAsc);
          case 'programSpecificKey':
            return this.compare((a.programSpecificKey || '').toLowerCase(), (b.programSpecificKey || '').toLowerCase(), isAsc);
          case 'createdDate':
            return this.compare((a.createdDate  || ''), (b.createdDate  || ''), isAsc);
          case 'settlementDate':
            return this.compare((a.settlementDate  || ''), (b.settlementDate || ''), isAsc);
          case 'reconciliationDate':
            return this.compare((a.reconciliationDate  || ''), (b.reconciliationDate || ''), isAsc);
          case 'createdBy':
            return this.compare((a.createdBy  || '').toLowerCase(), (b.createdBy || '').toLowerCase(), isAsc);
          case 'originatingSystemCode':
            return this.compare((a.originatingSystemCode  || '').toLowerCase(), (b.originatingSystemCode || '').toLowerCase(), isAsc);
          case 'shipToFirstName':
            return this.compare((a.shipToFirstName  || '').toLowerCase(), (b.shipToFirstName || '').toLowerCase(), isAsc);
          case 'shipToLastName':
            return this.compare((a.shipToLastName  || '').toLowerCase(), (b.shipToLastName || '').toLowerCase(), isAsc);
          case 'shipToCity':
            return this.compare((a.shipToCity || '').toLowerCase(), (b.shipToCity|| '').toLowerCase(), isAsc);
          case 'shipToCountry':
            return this.compare((a.shipToCountry || '').toLowerCase(), (b.shipToCountry|| '').toLowerCase(), isAsc);
          case 'orderState':
            return this.compare((a.orderState || '').toLowerCase(), (b.orderState|| '').toLowerCase(), isAsc);
          case 'paymentMethodCode':
            return this.compare((a.paymentMethodCode || '').toLowerCase(), (b.paymentMethodCode|| '').toLowerCase(), isAsc);
          case 'cardType':
            return this.compare((a.cardType  || '').toLowerCase(), (b.cardType || '').toLowerCase(), isAsc);
          case 'usdAmount':
            return this.compare((a.usdAmount  ||0), (b.usdAmount || 0), isAsc);
          case 'localCurrencyamount':
            return this.compare((a.localCurrencyamount ||0), (b.localCurrencyamount ||0), isAsc);
          case 'currencyCode':
            return this.compare((a.currencyCode || '').toLowerCase(), (b.currencyCode || '').toLowerCase(), isAsc);
          case 'billToFirstName':
            return this.compare((a.billToFirstName || '').toLowerCase(), (b.billToFirstName || '').toLowerCase(), isAsc);
          case 'billToLastName':
            return this.compare((a.billToLastName || '').toLowerCase(), (b.billToLastName || '').toLowerCase(), isAsc);
          case 'billToCity':
            return this.compare((a.billToCity || '').toLowerCase(), (b.billToCity || '').toLowerCase(), isAsc);
          case 'billToCountry':
            return this.compare((a.billToCountry || '').toLowerCase(), (b.billToCountry || '').toLowerCase(), isAsc);
          case 'fraudScore':
            return this.compare((a.fraudScore || '').toLowerCase(), (b.fraudScore || '').toLowerCase(), isAsc);
          case 'reasonCode':
            return this.compare((a.reasonCode || '').toLowerCase(), (b.reasonCode || '').toLowerCase(), isAsc);
          case 'customerID':
            return this.compare((a.customerID || '').toLowerCase(), (b.customerID || '').toLowerCase(), isAsc);
          case 'pgmResponseCode':
            return this.compare((a.pgmResponseCode || '').toLowerCase(), (b.pgmResponseCode || '').toLowerCase(), isAsc);
          case 'pgmResponseDescription':
            return this.compare((a.pgmResponseDescription || '').toLowerCase(), (b.pgmResponseDescription || '').toLowerCase(), isAsc);
          case 'project':
            return this.compare((a.project ||0), (b.project ||0), isAsc);
          case 'jeAccountNumber':
            return this.compare((a.jeAccountNumber ||0), (b.jeAccountNumber ||0), isAsc);
          case 'fraudIndicator':
            return this.compare((a.fraudIndicator || '').toLowerCase(), (b.fraudIndicator || '').toLowerCase(), isAsc);
          case 'fraudCustomerType':
            return this.compare((a.fraudCustomerType || '').toLowerCase(), (b.fraudCustomerType || '').toLowerCase(), isAsc);
          default:
            return 0;
        }
      });
    }
    this.dataSource.data = sortedData;
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
