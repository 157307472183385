import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import {DatePipe, ViewportScroller} from '@angular/common';
import {OrderSearchService} from '../service/order-search.service';
import {Observable} from 'rxjs';
import {OrderService} from '../service/order.service';
import {map, startWith} from 'rxjs/operators';
import { SharedService } from '../service/shared.service';
import { User } from '../model/user';
import { CountryRegionService } from '../service/country-region.service';
import { PaymentMethods, QuickDateSelect, OrderState, Decision, OrderAmountFilter, CardType, PaymentType, TransactionType } from '../model/enums/order-search';

export interface CountryList {
  name: string,
  id: string
}

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss'],
})
export class OrderSearchComponent implements OnInit {
  datePipe = new DatePipe('en-US');
  now = Date.now();
  quickDate = null;
  today = null;
  lastWeek = null;
  lastMonth = null;
  yesterday = null;
  quickDateSelect = '';
  selectedDate = '';
  searchForm: FormGroup;
  dateSelectForm: FormGroup;
  bsInlineValue = new Date();
  bsInlineRangeValue: Date[];
  public value = new Date();
  public fromDate = new Date();
  public toDate= new Date();
  maxDate = new Date();
  public fullFormat = 'mediumDate';
  formSubmitted = false;
  errorMsg: any;
  OrderSearchResults: Observable<any>;
  oscConfigCodes = [];
  searchRequest:any = [];
  searchQuickDate:any = [];
  startTime:string = '00:00';
  endTime:string = '23:59';
  dateTimeDisabled: boolean = false;
  searchError: boolean = false;
  countryList: CountryList[];
  countryFilterOptions: Observable<CountryList[]>;
  oscFilterOptions: Observable<string[]>;
  loggedInUser: User;
  PaymentMethods = PaymentMethods;
  QuickDateSelect = QuickDateSelect;
  OrderState = OrderState;
  Decision = Decision;
  OrderAmountFilter = OrderAmountFilter;
  CardType = CardType;
  PaymentType = PaymentType;
  TransactionType = TransactionType;

  constructor(
    private OrderSearch: OrderSearchService,
    private OrderService: OrderService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private scroll: ViewportScroller,
    private countryRegionService: CountryRegionService
  ) {}

  ngOnInit(): void {
    const fromAction = this.route.snapshot.queryParams.fromAction;
    if('revise' !== fromAction){
      this.clearFormsInSessionStorage();
    }
    this.sharedService.userInfo$.subscribe(user => {
      if (user) {
        this.loggedInUser = user;
        if (user) {
          this.oscConfigCodes = user.oscDetails.map(osc => osc.oscCode).sort();
          sessionStorage.setItem('oscConfigCodes', JSON.stringify(this.oscConfigCodes));
        }
        // this.getOscConfigCodes();
        this.countryList = this.countryRegionService.getCountryCodes();
        this.createForm();
      }
    });
    this.countryFilterOptions = this.searchForm.controls.testCenterCountry.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => this._filter(value))
      );

    this.oscFilterOptions = this.searchForm.get('originatingSystemCode')!.valueChanges
      .pipe(
        startWith(''),
        map((value: string) => this._filterOSC(value))
      );


  }
  scrollToTop(){
    this.scroll.scrollToPosition([0,0]);
}

  displayFn(value: string): string {
    return value ? value : '';
  }

  createForm() {
    this.searchForm = new FormGroup({
      'orderState': new FormControl(null),
      'programSpecificDate': new FormControl(null),
      'transactionType': new FormControl(null),
      'billToFirstName': new FormControl(null),
      'billToLastName': new FormControl(null),
      'billToEmail': new FormControl(null),
      'billToPhone': new FormControl(null),
      'billToCity': new FormControl(null),
      'billToCountry': new FormControl(null),
      'shipToFirstName': new FormControl(null),
      'shipToLastName': new FormControl(null),
      'shipToCountry': new FormControl(null),
      'shipToCity': new FormControl(null),
      'shipToEmail': new FormControl(null),
      'shipToPhone': new FormControl(null),
      'customerId': new FormControl(null),
      'programSpecificKey': new FormControl(null),
      'testCenterCountry': new FormControl(null),
      'originatingSystemCode': new FormControl(null),
      'oscSessionId': new FormControl(null),
      'orderId': new FormControl(null),
      'lastReasonCode': new FormControl(null),
      'reasonCode': new FormControl(null),
      'decision': new FormControl(null),
      'amountFilter': new FormControl(null),
      'amount': new FormControl(null),
      'paymentType': new FormControl(null),
      'paymentMethod': new FormControl(null),
      'cardType': new FormControl(null),
      'subscriptionId': new FormControl(null),
      'maskedAccountNumber': new FormControl(null),
      'last4DigitOfCreditCard': new FormControl(null),
      'bankAaccountNumber': new FormControl(null),
      'bankRoutingNumber': new FormControl(null),
      'currency': new FormControl(null),
      'bin': new FormControl(null),
      'fraudIndicator': new FormControl(null),
      'fraudCustomerType': new FormControl(null),
      'cfsFlag': new FormControl(null),
      'noMtchVndrSuppliedId' : new FormControl(null),
      'arnRrnNo' : new FormControl(null),
      'transactionAttemptCount' : new FormControl(null)
    }, [this.atLeastOneValue]);

    this.dateSelectForm = new FormGroup(
      {
        quickDateSelect: new FormControl(null),
        fromDate: new FormControl(null),
        toDate: new FormControl(null),
        fromTime: new FormControl(null),
        toTime: new FormControl(null),
        dateFor: new FormControl('created')
      },
      [this.atLeastOneValue]
    );
    this.setDataFromSessionStorage();

    this.dateSelectForm.get('fromDate').valueChanges.subscribe(data=>{
      if(data){
        this.dateSelectForm.get('quickDateSelect').disable();
      }else{
        this.dateSelectForm.get('quickDateSelect').enable();
      }
    });
  }

  clearQuickDate(){
    this.dateTimeDisabled = false;
    this.enableOrDisableFields(false);
    this.dateSelectForm.get('quickDateSelect').setValue('none');
    this.selectedDate = '';
  }

  onDateChange() {
    this.selectedDate = this.dateSelectForm.value.quickDateSelect;
    this.searchQuickDate = [];//reset on change
    this.dateTimeDisabled = true;
    this.enableOrDisableFields(true);
    let currentDate = new Date();
    const weekStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());
    switch (this.selectedDate) {
      case 'today':
        this.quickDate = this.datePipe.transform(this.now, 'mediumDate');
        this.addSearchQuickDate(this.now, this.now, this.startTime, this.endTime);
        break;
      case 'yesterday':
        currentDate.setDate(currentDate.getDate() - 1);
        this.quickDate = this.datePipe.transform(currentDate, 'mediumDate');
        this.addSearchQuickDate(currentDate, currentDate, this.startTime, this.endTime);
        break;
      case 'week':
        const weekStartTime = weekStart.getTime();
         // currentDate.setDate(currentDate.getDate());
        // currentDate.setDate(currentDate.getDate() - currentDate.getDay() );
        this.today = this.datePipe.transform(weekStartTime, 'mediumDate');
        this.quickDate = this.datePipe.transform(currentDate, 'mediumDate');
        this.addSearchQuickDate(weekStartTime, currentDate,   this.startTime, this.endTime);
        break;
      case 'lastWeek':
        currentDate.setFullYear(weekStart.getFullYear(),weekStart.getMonth(),weekStart.getDate()-7);
        const lastWeekEndDate = new Date();
        lastWeekEndDate.setFullYear(currentDate.getFullYear(),currentDate.getMonth(),currentDate.getDate() + 6);
        this.yesterday = this.datePipe.transform(lastWeekEndDate, 'mediumDate');
        this.lastWeek = this.datePipe.transform(currentDate, 'mediumDate');
        this.addSearchQuickDate(currentDate, lastWeekEndDate, this.startTime, this.endTime);
        break;
      case 'month':
        currentDate.setDate(currentDate.getDate() );
        const currentMonthStDt = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime();
        this.today = this.datePipe.transform(currentMonthStDt, 'mediumDate');
        this.quickDate = this.datePipe.transform(currentDate, 'mediumDate');
        this.addSearchQuickDate(currentMonthStDt, currentDate, this.startTime, this.endTime);
        break;
      case 'lastMonth':
        const lastMonthStartDt = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1).getTime();
        const currMonthStDt = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        currentDate.setDate(currMonthStDt.getDate() - 1);
        this.yesterday = this.datePipe.transform(currentDate, 'mediumDate');
        this.lastMonth = this.datePipe.transform(lastMonthStartDt , 'mediumDate');
        this.addSearchQuickDate(lastMonthStartDt, currentDate, this.startTime, this.endTime);
        break;
      default:
        this.dateTimeDisabled = false;
        this.enableOrDisableFields(false);
    }
  }

  addSearchQuickDate(fromDate:any, toDate:any, startTime:any, toTime:any) {
    this.searchQuickDate.push({'field': this.dateSelectForm.controls['dateFor'].value + 'FromDate', 'value': this.datePipe.transform(fromDate, `yyyy-MM-ddT${startTime}ZZZZZ`)});
    this.searchQuickDate.push({'field':this.dateSelectForm.controls['dateFor'].value + 'ToDate', 'value': this.datePipe.transform(toDate, `yyyy-MM-ddT${toTime}ZZZZZ`)});
  }

  createOrderSearchRequest(group: FormGroup): void {
    let toDate = '',
      toTime = '',
      fromDate = '',
      fromTime = '',
      amountFilter = '',
      amount = '';
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl.value) {
        switch (key) {
          case 'amountFilter':
            amountFilter = abstractControl.value;
            break;
          case 'amount':
            amount = abstractControl.value;
            break;
          case 'toDate':
            toDate = this.datePipe.transform(
              abstractControl.value,
              'MM-dd-yyyy'
            );
            break;
          case 'toTime':
            toTime = this.datePipe.transform(
              abstractControl.value,
              'HH:mm'
            );
            break;
          case 'fromDate':
            fromDate = this.datePipe.transform(
              abstractControl.value,
              'MM-dd-yyyy'
            );
            break;
          case 'fromTime':
            fromTime = this.datePipe.transform(
              abstractControl.value,
              'HH:mm'
            );
            break;
          case 'quickDateSelect': //pass-on
            break;
          case 'dateFor': //pass-on
            break;
          default:
            this.searchRequest.push({
              field: key,
              value: abstractControl.value,
            });
          }
        }
    });
    if (amount) {
      const concatAmountFilter = amountFilter + amount;
      this.searchRequest.push({ field: 'amount', value: concatAmountFilter });
    }
    const timezoneDatePipe = new DatePipe('en-US');
    if (fromDate) {
      const concatFromDateTime = timezoneDatePipe.transform(fromDate, 'yyyy-MM-ddT'+fromTime+'ZZZZZ');
      this.searchRequest.push({'field': this.dateSelectForm.controls['dateFor'].value + 'FromDate', 'value':concatFromDateTime});
    }
    if (toDate) {
      const concatToDateTime = timezoneDatePipe.transform(toDate, 'yyyy-MM-ddT'+toTime+'ZZZZZ');
      this.searchRequest.push({'field': this.dateSelectForm.controls['dateFor'].value + 'ToDate', 'value':concatToDateTime});
    }
  }

  saveToSessionStorage() {
    sessionStorage.setItem('searchForm', JSON.stringify(this.searchForm.value));
    const val = this.dateSelectForm.value;
    if (val) {
      const data = {};
      if (val.quickDateSelect) {
        data['quickDateSelect'] = val.quickDateSelect.toString();
      }
      if (val.toDate) {
        data['toDate'] = val.toDate.toString();
      }
      if (val.toTime) {
        data['toTime'] = val.toTime.toString();
      }
      if (val.fromDate) {
        data['fromDate'] = val.fromDate.toString();
      }
      if (val.fromTime) {
        data['fromTime'] = val.fromTime.toString();
      }
      if (val.dateFor) {
        data['dateFor'] = val.dateFor.toString();
      }

      sessionStorage.setItem('dateSelectForm', JSON.stringify(data));
    }

  }

  clearFormsInSessionStorage(){
    sessionStorage.removeItem('searchForm');
    sessionStorage.removeItem('dateSelectForm');
  }

  setDataFromSessionStorage() {
    let searchFormValue = sessionStorage.getItem('searchForm');
    if (searchFormValue) {
      searchFormValue = JSON.parse(searchFormValue);
      const keys = Object.keys(searchFormValue);
      keys.forEach((prop: string) => {
        this.searchForm.controls[prop].setValue(searchFormValue[prop]);
      });

    }

    let dateSelectFormValue = sessionStorage.getItem('dateSelectForm');
    if (dateSelectFormValue) {
      dateSelectFormValue = JSON.parse(dateSelectFormValue);
      if (dateSelectFormValue['quickDateSelect']) {
        this.dateSelectForm.controls['quickDateSelect'].setValue(dateSelectFormValue['quickDateSelect']);
      }
      if (dateSelectFormValue['toDate']) {

        this.dateSelectForm.controls['toDate'].setValue(new Date(dateSelectFormValue['toDate']));
      }
      if (dateSelectFormValue['toTime']) {
        this.dateSelectForm.controls['toTime'].setValue(new Date(dateSelectFormValue['toTime']));
      }
      if (dateSelectFormValue['fromDate']) {
        this.dateSelectForm.controls['fromDate'].setValue(new Date(dateSelectFormValue['fromDate']));
      }
      if (dateSelectFormValue['fromTime']) {
        this.dateSelectForm.controls['fromTime'].setValue(new Date(dateSelectFormValue['fromTime']));
      }
      if (dateSelectFormValue['dateFor']) {
        this.dateSelectForm.controls['dateFor'].setValue(dateSelectFormValue['dateFor']);
      }
      if(Object.keys(dateSelectFormValue).length>0) {
        this.onDateChange();
      }
    }
  }

  updateOrginatedSystemCode(completeRequest){

   const orgCodeFilter= completeRequest.filter(cr=>cr.field=='originatingSystemCode');
   if ( orgCodeFilter.length==0 && sessionStorage.getItem('oscConfigCodes')){
     completeRequest.push({
       field:'originatingSystemCode',
       value:JSON.parse(sessionStorage.getItem('oscConfigCodes')).join(",")
     })
   } else {
     orgCodeFilter[0].value = orgCodeFilter[0].value.filter(o => o != 'ALL').join(",");
   }
    completeRequest= completeRequest.filter(cr=> cr.value );
  }

  toggleAllOscCodeSelection(selectedList) {
    if (selectedList?.length > 0 && selectedList[0] === 'ALL') {
      this.searchForm.controls['originatingSystemCode'].setValue([
        'ALL',
        ...this.oscConfigCodes,
      ]);
    }else{
      this.searchForm.controls['originatingSystemCode'].setValue([]);
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    if(this.dateSelectForm.controls['fromDate'].value && !this.dateSelectForm.controls['toDate'].value){
      this.dateSelectForm.controls['toDate'].setValue(new Date());
    }
    this.saveToSessionStorage();
    this.searchRequest = []; //reset on each submit
    let completeRequest = []; //use to merge with quick date array
    this.createOrderSearchRequest(this.searchForm);
    this.createOrderSearchRequest(this.dateSelectForm);
    //add quick date into request
    if (this.searchQuickDate.length > 0) {
      completeRequest = this.searchRequest.concat(this.searchQuickDate);
    } else {
      completeRequest = this.searchRequest;
    }
    //send order search criteria to order-search-result.component
    this.OrderSearch.changeOrderSearchCriteria(JSON.stringify(completeRequest));
    // update osc from session storage if not present in request
    this.updateOrginatedSystemCode(completeRequest);
    //make a request to PSL service
    this.OrderSearch.orderSearch(completeRequest).subscribe(
      (result: any) => {
        if (result.content.length == 1) {
          const searchResult= result.content[0];
          this.router.navigate([
            '/order-search-details',
            searchResult.transactionId,
          ], {queryParams: {orderState: searchResult?.orderState ? searchResult.orderState : ''}});
        } else {
          this.router.navigate(['/order-search-result', 'order-search']); // if more than one records
        }
      },
      (error: any) => {
        this.searchError = true;
        this.scrollToTop();
        console.error(
          'Error Occurred while searching for order => ' + JSON.stringify(error)
        );
      }
    );
  }

  atLeastOneValue(form: FormGroup): ValidationErrors {
    return Object.keys(form.value).some((key) => !!form.value[key])
      ? null //false;
      : { atLeastOneRequired: 'At least one should be selected' };
  }

  resetForm() {
    this.searchForm.reset();
    this.dateSelectForm.reset();
    this.searchQuickDate = [];
    this.dateTimeDisabled = false;
    this.enableOrDisableFields(false);
    this.searchError = false;
    this.clearFormsInSessionStorage();
    this.selectedDate=null;
  }

  getOscConfigCodes() {
    const obs = this.OrderService.getOscConfigCodes();
    obs.subscribe(
      (data) => {
        this.oscConfigCodes = data;
      },
      (err) => {
        console.log('Error retrieving OSC config codes: ' + JSON.stringify(err));
        this.oscConfigCodes = [];
      }
    );
  }

  clearDates(){
    this.dateSelectForm.controls['fromDate'].setValue(null);
    this.dateSelectForm.controls['toDate'].setValue(null);
  }

  private _filter(name: string) : CountryList[] {
    const filterValue = name.toLowerCase();
    return this.countryList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterOSC(name: string) : string[] {
    const filterValue = name.toLowerCase();
    return this.oscConfigCodes.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  addDateEvent() {
    const fromTime = new Date();
    fromTime.setHours(0);
    fromTime.setMinutes(0);
    this.dateSelectForm.controls['fromTime'].setValue(fromTime);
    const toTime = new Date();
    toTime.setHours(23);
    toTime.setMinutes(59);
    this.dateSelectForm.controls['toTime'].setValue(toTime);
  }

  enableOrDisableFields(dateTimeDisabled){
    const fieldsUpdated = ['fromTime', 'toTime']
        fieldsUpdated.forEach((value: string) => {
          dateTimeDisabled ? this.dateSelectForm.controls[value].disable() : this.dateSelectForm.controls[value].enable();
      });
    }

    isVldTxAmt(){
      const transactionAttemptCountCtrl=this.searchForm.get('transactionAttemptCount');
      if(transactionAttemptCountCtrl.value){
        if(this.searchForm.get('orderId') && this.searchForm.get('orderId').value){
          return true;
        }else{
          return false;
        }
      }
      return true;
    }
}
