import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SourceType } from './model/enums/source-type';
import { Amplify, type ResourcesConfig } from 'aws-amplify';

import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { sessionStorage } from 'aws-amplify/utils';

import { environment } from 'src/environments/environment';
import { AuthenticationService } from './service/authentication.service';
import {AmplifyOutputsAuthProperties} from '@aws-amplify/core/dist/esm/singleton/AmplifyOutputs/types'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'admin-ui';

  constructor(private _router: Router,
    private _authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    /****************** EPMS Application Login ******************/
    const winLocation: Location = window.location;
    const pathName = winLocation.pathname;
    const urlParams = new URLSearchParams(winLocation.search);
    const redirectFromIdp = (urlParams.has('code') && (pathName.indexOf('epms-admin-home') > -1));
    const isLogout = (pathName.indexOf('logout') > -1);
    const isExternalUrl = (winLocation?.hostname?.indexOf('ext') > -1);
    
    AppComponent.configureAmplify(isExternalUrl);
    if (this.verifyIsRedirectFromPaymentUi(urlParams, pathName)) {
      this._authenticationService.initOnReload(winLocation);
    } else {
      if (isLogout) {
        this._router.navigate(['/logout']);
      } else {
        this._authenticationService.init(redirectFromIdp);
      }
    }
  }

  verifyIsRedirectFromPaymentUi(urlParams: URLSearchParams, pathName: string): boolean {
    let isFromPaymentUi = false;
    if (urlParams.has('t') && urlParams.has('status') && (urlParams.has('sourceType'))) {
      const sourceType = urlParams.get('sourceType').toLocaleLowerCase();
      if ((sourceType in SourceType) && pathName.indexOf('/virtual-terminal') > -1) {
        isFromPaymentUi = true;
      }
    }
    return isFromPaymentUi;
  }

  /**
     * Configure Amplify
     */
  private static configureAmplify(isExternalUrl: boolean): void {
    const auth : AmplifyOutputsAuthProperties = isExternalUrl? environment.cognitoExt : environment.cognito;

    const authConfig: ResourcesConfig['Auth'] = {
      Cognito: {
        userPoolId : auth.user_pool_id,
        userPoolClientId: auth.user_pool_client_id,
        loginWith:{
          email: true,
          oauth: {
            domain: auth.oauth.domain,
            redirectSignIn : auth.oauth.redirect_sign_in_uri,
            redirectSignOut: auth.oauth.redirect_sign_out_uri,
            responseType: 'code',
            scopes: auth.oauth.scopes,
            providers : [{custom : isExternalUrl ? environment.CUSTOM_IDENTITY_PROVIDER_EXT : environment.CUSTOM_IDENTITY_PROVIDER}]
          },
        },
      }
    };
    
    Amplify.configure({
      Auth: authConfig
    });

    cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

   
  }
}

