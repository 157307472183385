import {Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {faChevronLeft, faSearch,faDownload} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from '@angular/router'
import {UserManagementService} from '../../service/user-management.service';
import {User} from "../../model/user";
import {UserAuditInfo} from "../../model/user-audit-info";
import {MatSort, Sort} from "@angular/material/sort";
import {MatHeaderCellDef, MatTable, MatTableDataSource} from "@angular/material/table";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import { formatDate } from '@angular/common';
import { ExportService } from 'src/app/service/export-service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  faSearch = faSearch;
  faDownload = faDownload;
  faChevronLeft = faChevronLeft;
  userData: User;
  userLoginID;
  userAuditList:UserAuditInfo[];
  dataSource;
  loginHistoryDataSource;
  exportOption = 'xls';
  loginHistoryDataSourceSortDescDir=true;
  modalRef: BsModalRef;
  exportActivityLog = true;
  renderedDataSource:any;

  @ViewChild('userAccountActivityLog', {read:MatSort,static: false}) sort: MatSort;
  @ViewChild('loginHistory', {read:MatSort, static: false}) loginHistorySort: MatSort;
  @ViewChild(MatTable) userAccountMatTable: MatTable<any>;
  @ViewChildren(MatHeaderCellDef) headerCells: QueryList<any>;
  @ViewChild('loginHistory',{read:MatTable,static: false}) loginHistoryTable: MatTable<any>;

  displayedColumns: string[] = ['userId','userLoginId','firstName','lastName','emailId','actionDtm','userNam','actionTxt','oldValue','newValue'];
  loginHistoryDisplayedColumns: string[] = ['userId','userLoginId','firstName','lastName','emailId','userLoginTimestamp'];
  
  exportedUserAccountColumnsJson=[];
  exportedLoginHistoryColumnsJson=[];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userMgmtService: UserManagementService,
    private modalService: BsModalService,private exportService:ExportService) {
    this.activatedRoute.queryParams.subscribe(data => {
      this.userLoginID = data['userLoginID'];
    });
  }

  loadAuditInfo() {
    const obs = this.userMgmtService.getUserAuditDetails(this.userData.userId);
    obs.subscribe(data => {
      for(let item of data){
        item.userLoginId = this.userData.userLoginId;
        item.emailId = this.userData.emailId;
        item.firstName = this.userData.firstName;
        item.lastName = this.userData.lastName;
        item.userId = this.userData.userId;
      }
      this.userAuditList = data;
      this.setData(data);
    })
  }

  loadLastLoginData(){
    const obs = this.userMgmtService.getUserRecordLoginDetails(this.userData.userId);
    obs.subscribe(data => {
      for(let item of data){
        item.userLoginId = this.userData.userLoginId;
        item.emailId = this.userData.emailId;
        item.firstName = this.userData.firstName;
        item.lastName = this.userData.lastName;
      }
      this.loginHistoryDataSource = new MatTableDataSource(data);
      this.loginHistoryDataSource.sort=this.loginHistorySort;
    })
  }

  sortLoginHistoryData(sort:Sort){
    let data=this.loginHistoryDataSource.data;

    this.loginHistoryDataSourceSortDescDir=!this.loginHistoryDataSourceSortDescDir;

    if( this.loginHistoryDataSourceSortDescDir){
      data=data.sort((a,b)=>{
        return a.userLoginTimestamp<b.userLoginTimestamp?1:-1;
      });
    }else{
      data=data.sort((a,b)=>{
        return a.userLoginTimestamp<b.userLoginTimestamp?-1:1;
      });
    }
    this.loginHistoryDataSource = new MatTableDataSource([...data]);
  }

  setData(auditDataList) {
    this.dataSource = new MatTableDataSource(auditDataList);
    this.dataSource.sort = this.sort;
    this.dataSource.connect().subscribe((data)=>{
      this.renderedDataSource=data;
    });
  }

  ngOnInit(): void {
    const obs = this.userMgmtService.getUserByLoginId(this.userLoginID);
    obs.subscribe(userData => {
      this.userData = userData;
      this.loadAuditInfo();
      this.loadLastLoginData();
    });
  }

  getOscCodes(user: User) {
    const oscSet = new Set();

    if (user.oscDetails) {
      user.oscDetails.forEach(oscDetail => {
        oscSet.add(oscDetail.oscCode);
      });
    }
    return Array.from(oscSet)?.sort().join(', ');
  }

  getProgramGroupList(user: User) {
    const programGroupList = new Set();

    if (user.oscDetails) {
      user.oscDetails.forEach(oscDetail => {
        programGroupList.add(oscDetail.programName);

      });
    }
    return Array.from(programGroupList).join(',');
  }

  back() {
    this.router.navigate(['/user-management']);
  }

  openModal(exportSave: TemplateRef<any>) {
    this.exportActivityLog=true;
    this.modalRef = this.modalService.show(exportSave);
  }
  openLoginHistoryExportModal(exportSave: TemplateRef<any>) {
    this.exportActivityLog=false;
    this.modalRef = this.modalService.show(exportSave);
    return false;
  }

  exportData(exportOption) {
    if(this.exportActivityLog){
      this.exportAccountActivityLog(exportOption);
    } else{
      this.exportLoginHistoryData(exportOption);
    }
  }

  formatAccountActivityLogData(data:any[]){
    let index=0;
    this.exportedUserAccountColumnsJson=[];
    data.forEach(json=>{
  
      let expJson={
        'User ID':json['userId'],
        'User Login Id':json['userLoginId'],
        'First Name':json['firstName'],
        'Last Name':json['lastName'],
        'Email Id':json['emailId'],
        'Entry Date':formatDate(json['actionDtm'],this.exportService.DATE_FORMAT,this.exportService.TIMEZONE),
        'Updated By':json['userNam'],
        'Action Taken':json['actionTxt'],
        'Before Data':json['beforeData'],
        'After Data':json['afterData']
        };
     
      this.exportedUserAccountColumnsJson.push(expJson);
      index++;
    });
  }

  formatLoginHistoryData(data){
    let index=0;
    this.exportedLoginHistoryColumnsJson=[];
    data.forEach(json=>{
      let expJson={
        'User ID':json['userId'],
        'User Login Id':json['userLoginId'],
        'First Name':json['firstName'],
        'Last Name':json['lastName'],
        'Email Id':json['emailId'],
        'Login Date':formatDate(json['userLoginTimestamp'],this.exportService.DATE_FORMAT,this.exportService.TIMEZONE)
      };
      this.exportedLoginHistoryColumnsJson.push(expJson);
      index++;
    });
  }

  exportAccountActivityLog(exportOption) {
    this.formatAccountActivityLogData(this.renderedDataSource);
    switch (exportOption) {
      case 'xls':
        this.exportService.exportFile('xlsx',`UserAccountActivityLog`, 'UserAccountActivityLog',this.exportedUserAccountColumnsJson);
        break;
      case 'csv':
        this.exportService.exportFile('csv', `UserAccountActivityLog`, 'UserAccountActivityLog',this.exportedUserAccountColumnsJson);
        break;
    }
  }

  exportLoginHistoryData(exportOption) {
    this.formatLoginHistoryData(this.loginHistoryDataSource.filteredData);
    switch (exportOption) {
      case 'xls':
        this.exportService.exportFile('xlsx', `LoginHistory`, 'LoginHistory',this.exportedLoginHistoryColumnsJson);
        break;
      case 'csv':
        this.exportService.exportFile('csv', `LoginHistory`, 'LoginHistory',this.exportedLoginHistoryColumnsJson);
        break;
    }
  }
}
