import { Injectable } from '@angular/core';
import { CDNScript } from '../model/cdn-script';

export const cdnScripts:CDNScript[]=[
  { name: 'sheetJs', src: 'https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.mini.min.js' }
];


@Injectable({
  providedIn: 'root',
})

export class CdnLoadService {
  private scripts: any = {};
  sheetJs:any;  
  constructor() {
    cdnScripts.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.onload = () => {
          this.scripts[name].loaded = true;
          console.log('${name} Loaded.');
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

}
