<hr />
  <div class="row d-flex mb-3">
    <div class="col-md-6">
      <h3>Search Results</h3>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
      <button class="btn btn-primary" (click)="openModal(exportSave)">
        Export
      </button>
    </div>
  </div>
<div class="col-lg-3 col-md-3 col-12">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Ex. ium"
      #input
    />
  </mat-form-field>
</div>
<div class="col-md-12">
  <mat-table
    [dataSource]="dataSource"
    matSort  matSortDisableClear
    (matSortChange)="onSortChange($event)"
    class="table table-responsive table-bordered"
  >
    <ng-container matColumnDef="epmsOrderId" sticky>
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Order Id
      </th>
      <td mat-cell *matCellDef="let element">
        <a
          class="link"
          [routerLink]="['/order-search-details', element.transactionId]"
          [queryParams]="{
            orderState: element?.orderState ? element.orderState : ''
          }"
          >{{ element.epmsOrderId }}</a
        >
        </td>
    </ng-container>

    <ng-container matColumnDef="transactionAttemptCount">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Transaction Attempt Count
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.transactionAttemptCount }}
      </td>
    </ng-container>
    <ng-container matColumnDef="programSpecificKey">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Program Specific Key
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.programSpecificKey }}
      </td>
    </ng-container>
    <ng-container matColumnDef="createdDate">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Transaction Created Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdDate | date: "yyyy-MM-dd HH:mm:ss" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="settlementDate">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Settlement Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.settlementDate | date: "yyyy-MM-dd HH:mm:ss" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="reconciliationDate">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Reconciliation Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.reconciliationDate | date: "yyyy-MM-dd HH:mm:ss" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Created By
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdBy }}
      </td>
    </ng-container>
    <ng-container matColumnDef="originatingSystemCode">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        OSC
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.originatingSystemCode }}
      </td>
    </ng-container>
    <ng-container matColumnDef="shipToFirstName">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Ship to First Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.shipToFirstName }}</td>
    </ng-container>
    <ng-container matColumnDef="shipToLastName">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Ship to Last Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.shipToLastName }}</td>
    </ng-container>
    <ng-container matColumnDef="shipToCity">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Ship to City
      </th>
      <td mat-cell *matCellDef="let element">{{ element.shipToCity }}</td>
    </ng-container>
    <ng-container matColumnDef="shipToCountry">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Ship to Country
      </th>
      <td mat-cell *matCellDef="let element">{{ element.shipToCountry }}</td>
    </ng-container>
    <ng-container matColumnDef="orderState">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Order State
      </th>
      <td mat-cell *matCellDef="let element">{{ element.orderState }}</td>
    </ng-container>
    <ng-container matColumnDef="decision">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Decision
      </th>
      <td mat-cell *matCellDef="let element">{{ element.decision }}</td>
    </ng-container>
    <ng-container matColumnDef="paymentMethodCode">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Payment Method
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.paymentMethodCode }}
      </td>
    </ng-container>
    <ng-container matColumnDef="cardType">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Card Type
      </th>
      <td mat-cell *matCellDef="let element">{{ element.cardType }}</td>
    </ng-container>
    <ng-container matColumnDef="usdAmount">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Amount
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.usdAmount?.toFixed(2) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="localCurrencyamount">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Local Currency Amount
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.localCurrencyamount?.toFixed(2) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="currencyCode">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Currency Code
      </th>
      <td mat-cell *matCellDef="let element">{{ element.currencyCode }}</td>
    </ng-container>
    <ng-container matColumnDef="billToFirstName">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Bill To First Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.billToFirstName }}</td>
    </ng-container>
    <ng-container matColumnDef="billToLastName">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Bill To Last Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.billToLastName }}</td>
    </ng-container>
    <ng-container matColumnDef="billToCity">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Bill To City
      </th>
      <td mat-cell *matCellDef="let element">{{ element.billToCity }}</td>
    </ng-container>
    <ng-container matColumnDef="billToCountry">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Bill To Country
      </th>
      <td mat-cell *matCellDef="let element">{{ element.billToCountry }}</td>
    </ng-container>
    <ng-container matColumnDef="fraudScore">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Fraud Score
      </th>
      <td mat-cell *matCellDef="let element">{{ element.fraudScore }}</td>
    </ng-container>
    <ng-container matColumnDef="reasonCode">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Reason Code
      </th>
      <td mat-cell *matCellDef="let element">{{ element.reasonCode }}</td>
    </ng-container>
    <ng-container matColumnDef="customerID">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Customer ID
      </th>
      <td mat-cell *matCellDef="let element">{{ element.customerID }}</td>
    </ng-container>
    <ng-container matColumnDef="pgmResponseCode">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Program Response Code
      </th>
      <td mat-cell *matCellDef="let element">{{ element.pgmResponseCode }}</td>
    </ng-container>
    <ng-container matColumnDef="pgmResponseDescription">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Program Response Description
      </th>
      <td mat-cell *matCellDef="let element">{{ element.pgmResponseDescription }}</td>
    </ng-container>
    <ng-container matColumnDef="project">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Project
      </th>
      <td mat-cell *matCellDef="let element">{{ element.project }}</td>
    </ng-container>
    <ng-container matColumnDef="jeAccountNumber">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        JE Account Number
      </th>
      <td mat-cell *matCellDef="let element">{{ element.jeAccountNumber }}</td>
    </ng-container>
    <ng-container matColumnDef="fraudIndicator">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        TC-40 Flag
      </th>
      <td mat-cell *matCellDef="let element">{{ element.fraudIndicator }}</td>
    </ng-container>
    <ng-container matColumnDef="noMtchVndrSuppliedId">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        No Match Vendor Supplied Order ID
      </th>
      <td mat-cell *matCellDef="let element">{{ element.noMtchVndrSuppliedId }}</td>
    </ng-container>
    <ng-container matColumnDef="fraudCustomerType">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        Fd Customer Type
      </th>
      <td mat-cell *matCellDef="let element">{{ element.fraudCustomerType }}</td>
    </ng-container>
    <ng-container matColumnDef="arnRrnNo">
      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
        ARN/RRN
      </th>
      <td mat-cell *matCellDef="let element">{{ element.arnRrnNo }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="example-second-footer-row" colspan="13">
        No data matching the filter "{{ input.value }}"
      </td>
    </tr>
  </mat-table>
  <mat-paginator
    [length]="100"
    [pageSizeOptions]="[25, 50, 100]"
    [pageIndex]="pgIndex"
    (page)="onChangePage($event)"
  >
  </mat-paginator>
</div>

<ng-template #exportSave>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <fa-icon [icon]="faDownload"></fa-icon> Export Order Search Result
    </h4>
    <div align="end">
    <button mat-icon-button class="close-button" (click)="modalRef.hide()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  </div>
  <div class="modal-body">
    Please select a file format.

    <div>
      <mat-radio-group
        aria-labelledby="export-radio-group-label"
        class="export-radio-group"
        [(ngModel)]="exportOption"
      >
        <mat-radio-button class="export-radio-button" value="xls">
          Excel file (.xls)
        </mat-radio-button>
        <mat-radio-button class="export-radio-button" value="csv">
          Comma separated values (.csv)
        </mat-radio-button>
        <mat-radio-button class="export-radio-button" value="xml">
          XML
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-function" (click)="modalRef.hide()">
      Exit
    </button>
    <button class="btn btn-primary" (click)="exportData()">Export</button>
  </div>

</ng-template>
